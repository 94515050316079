import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: `Carolina Dorella's Portfolio`, // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: `Welcome to Carolina Dorella's Portfolio`, // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Carolina Dorella,',
  subtitle: 'World Traveler turned ',
  profession: 'Developer.',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'Carol_2020_Final.jpg',
  paragraphOne:
    'Hi! I’m Carolina Dorella, software developer. After years of international experience in Tourism and Business (and 109 countries visited 🌎), I landed in Spain and started the next chapter of my life, transitioning into the tech by concluding both a Full Stack Development Course and a Product Management one.',
  paragraphTwo:
    'Quality driven and always curious, I have been thoroughly enjoying the learning process, the collaboration and the opportunity to create meaningful products.',
  paragraphThree:
    'Currently part of the awesome team at Factorial HR working with React, Typescript and Ruby on Rails.',
  resume: 'https://drive.google.com/file/d/1fG6CyPso9AnwvsLvW3xpRFnD9tiZidVC/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'Home_Page_EsperancaProject.JPG',
    title: 'Esperança Project',
    info:
      'A volunteer sign-up full stack app solution for a non-profit organization. Inspired by "Esperança", a community of volunteers that hand out food to people living on the streets in Barcelona.',
    info2: '💻: React.js, Node.js, Express.js, RESTful API, MySQL, HTML, CSS, Bootstrap, Heroku.',
    url: 'https://github.com/cdorella/fspt2-volunteer-sign-up-project', // Originally "See live"
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Home_Page_nearEAT.JPG',
    title: 'nearEAT',
    info:
      'A full stack app built in collaboration with an international team, focused on giving visibility to restaurants prioritizing local, organic and seasonal produce.',
    info2:
      '💻: React.js, Node.js, Express.js, RESTful API, MySQL, HTML, CSS, Heroku. Includes React Router, Google Maps API integration, JWT authentication. ',
    url: 'https://github.com/cdorella/fspt2-km0-project',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Home_Page_CovidTalent.JPG',
    title: 'COVID-Talent',
    info:
      'An app designed to connect professionals that were laid off during the pandemic with companies looking to hire new talent. I implemented filters and query functionality as a feature extension to legacy code. ',
    info2: '💻: React.js, Node.js, Express.js, RESTful API, HTML, MySQL, CSS & Bootstrap.',
    url: 'https://github.com/EstherUrzanqui/fspt2-mvp-talent',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Home_Page_Backstage.JPG',
    title: 'The Backstage Contact List',
    info:
      'My playful version of a contact list, this is a full stack app implemented with CRUD principles, connecting React with newly learned Ruby and Ruby on Rails.',
    info2:
      '💻: React.js, Ruby on Rails, MySQL, HTML, CSS, Bootstrap. Includes React Router & React Hooks.',
    url: 'https://github.com/cdorella/contact_list',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Reach out and say Hi! Hola! Oi! Ciao!',
  btn: 'Email',
  email: 'caroldorella@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/carolinadorella/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/cdorella',
    },
    {
      id: nanoid(),
      name: 'medium',
      url: 'https://medium.com/@caroldorella',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
